import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import {
  FormArray,
  UntypedFormBuilder,
  FormControl,
  UntypedFormGroup,
  Validators
} from '@angular/forms';
import { DataService } from '../../../shared/services/data.service';
import { DatePipe } from '@angular/common';
// import * as moment from 'moment';
import moment from 'moment';
// import * as $ from 'jquery';
import $ from 'jquery';
// import { ToastrManager } from 'ng6-toastr-notifications';
import { ToastrService } from 'ngx-toastr';
import * as Highcharts from 'highcharts';
import { Constvar } from '../../../models/constVar';
import { LoaderService } from '../../../shared/services/loader.service';
import { orderBy } from 'lodash';

declare var require: any;
// let Boost = require('highcharts/modules/boost');
// let noData = require('highcharts/modules/no-data-to-display');
// let More = require('highcharts/highcharts-more');

// Boost(Highcharts);
// noData(Highcharts);
// More(Highcharts);
// noData(Highcharts);
// require('highcharts/modules/networkgraph')(Highcharts);

interface testLevel {
  value: string;
  viewValue: string;
}

@Component({
  selector: 'wtaf-failed-test-steps',
  templateUrl: './failed-test-steps.component.html',
  styleUrls: ['./failed-test-steps.component.css']
})
export class FailedTestStepsComponent implements OnInit {
  brand_name: any = 'All';
  brandLists: any = [];
  Fail_count = [];
  barChartLabels = [];
  updateFlag = true;
  highcharts: any = Highcharts;
  fromDate: string | null = null;
  toDate: string | null = null;

  testLevel: string = 'Smoke';
  environment: string = 'STG';

  failedRCATestStepsform: UntypedFormGroup;

  projectTypeList: any = [];
  isfromOninit: boolean = false;
  platformList: any = [];

  regionName: string = '';
  projectTypeName: String = '';
  projecttypeId: any;

  dataVariable: any = [];
  selectedValue: string = 'default';
  environmentList = ['STG', 'Dev', 'Prod', 'QA'];
  selectOption = 'Smoke';
  selectedENV = 'STG';
  selectedDate1: any;
  selectedDate2: any;
  selectedTestLevel: any = '- Smoke :';

  testlevellist: testLevel[] = [
    { value: 'Smoke', viewValue: 'Smoke' },
    { value: 'CICD', viewValue: 'CICD' },
    { value: 'Regression', viewValue: 'Regression' },
    { value: 'Compatibility', viewValue: 'Compatibility' },
    { value: 'Localization', viewValue: 'Localization' },
    { value: 'Trial-Run', viewValue: 'Trial-Run' }
  ];

  yearsMonth = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec'
  ];
  error: any = { isError: false, errorMessage: '' };
  isValidDate: any;
  environment_type = 'STG';

  chart: any;
  chartOptions: any = {
    chart: {
      plotBackgroundColor: null,
      plotBorderWidth: null,
      plotShadow: false,
      type: 'bar'
    },
    title: {
      text: ''
    },
    subtitle: {
      text: ''
    },
    xAxis: {
      categories: [],
      title: {
        text: ''
      }
    },

    yAxis: {
      min: 0,
      title: {
        text: '',
        align: 'high'
      },
      labels: {
        overflow: 'justify'
      }
    },
    tooltip: {
      valuePrefix: ' '
    },
    colors: ['#AF4E3F'],
    plotOptions: {
      bar: {
        colorByPoint: true,
        dataLabels: {
          enabled: true,
          inside: true
        }
      }
    },

    credits: {
      enabled: false
    },
    series: [
      {
        datalabels: {
          enable: true
        },
        showInLegend: false,
        name: 'Fail count',
        data: []
      }
    ]
  };

  constructor(
    private dataservice: DataService,
    public fb: UntypedFormBuilder,
    public DatePipe: DatePipe,
    public toastr: ToastrService,
    public loaderservice: LoaderService,
    private ref: ChangeDetectorRef
  ) {
    let nowDate = new Date();
    this.toDate = this.DatePipe.transform(nowDate, 'yyyy-MM-dd');
    let toUIDate = this.DatePipe.transform(nowDate, 'd MMM yyyy');
    this.selectedDate2 = ' - ' + toUIDate;
    this.fromDate = moment()
      .subtract('months', 1)
      .format('YYYY-MM-DD');
    let fromUIDate = moment()
      .subtract('months', 1)
      .format('DD MMM');
    this.selectedDate1 = ' ' + fromUIDate;

    this.failedRCATestStepsform = this.fb.group({
      startDate: [this.fromDate, [Validators.required]],
      endDate: [this.toDate, [Validators.required]],
      testLevel: ['Smoke', [Validators.required]],
      brand_name: ['All', [Validators.required]],
      environment: ['STG', [Validators.required]]
    });
  }

  ngOnInit() {
    this.loaderservice.show();

    setTimeout(() => {
      this.loaderservice.hide();
    }, 15000);

    $('#monthlyExecStatusFromDate').attr('max', this.toDate);
    $('#monthlyExecStatusToDate').attr('max', this.toDate);
    this.fetchFailedTestStepsCount(
      this.brand_name,
      this.fromDate,
      this.toDate,
      this.environment,
      this.testLevel
    );
    this.fetchBrandsbyRegion(1);
  }

  getMinDate(): string {
    const currentDate = new Date();
    const minDate = new Date();
    minDate.setDate(currentDate.getDate() - 30);
    return minDate.toISOString().split('T')[0];
  }

  getMaxDate(): string {
    const currentDate = new Date();
    return currentDate.toISOString().split('T')[0];
  }

  onFromDateChange(event: any, e) {
    this.fromDate = event.target.value;
    console.log('from: ', this.fromDate);
    const toDateInput = document.getElementById('toDate') as HTMLInputElement;
    toDateInput.disabled = false;
    this.fetchFailedTestStepsCount(
      this.brand_name,
      this.fromDate,
      this.toDate,
      this.environment,
      this.testLevel
    );

    let fromDate = this.DatePipe.transform(e.target.value, 'yyyy-MM-dd');
    let fromUIDate = this.DatePipe.transform(e.target.value, 'd MMM');
    let currentDate = new Date();
    let currentmonth = currentDate.getMonth();
    let currentyear = currentDate.getFullYear();
    let currentmonth1 = new Date(fromDate).getMonth();
    let currentyear1 = new Date(fromDate).getFullYear();
    this.selectedDate1 = ' ' + fromUIDate;
    console.log('Selected Date1 ' + this.selectedDate1);

    if (
      new Date(this.DatePipe.transform(currentDate, 'yyyy-MM-dd')).getTime() ===
      new Date(this.DatePipe.transform(fromDate, 'yyyy-MM-dd')).getTime()
    ) {
      let maxDate = fromDate;
      fromDate = moment(fromDate)
        .subtract(1, 'months')
        .format('YYYY-MM-DD');
      $('#exe_EndDate').attr(
        'min',
        this.DatePipe.transform(fromDate, 'yyyy-MM-dd')
      );
      $('#exe_EndDate').attr(
        'max',
        this.DatePipe.transform(maxDate, 'yyyy-MM-dd')
      );
    } else {
      let date_1 = new Date(currentyear, currentmonth);
      let date_2 = new Date(currentyear1, currentmonth1);
      if (date_1.getTime() === date_2.getTime()) {
        let maxDate = fromDate;
        fromDate = moment(fromDate)
          .subtract(1, 'months')
          .format('YYYY-MM-DD');
        let toDate = moment(
          this.DatePipe.transform(e.target.value, 'yyyy-MM-dd')
        )
          .add(1, 'months')
          .format('YYYY-MM-DD');
        $('#exe_EndDate').attr(
          'min',
          this.DatePipe.transform(fromDate, 'yyyy-MM-dd')
        );
        $('#exe_EndDate').attr(
          'max',
          this.DatePipe.transform(currentDate, 'yyyy-MM-dd')
        );
        this.failedRCATestStepsform.controls['exe_EndDate'].setValue(
          this.DatePipe.transform(currentDate, 'yyyy-MM-dd')
        );
      } else {
        let toDate = moment(
          this.DatePipe.transform(e.target.value, 'yyyy-MM-dd')
        )
          .add(1, 'months')
          .format('YYYY-MM-DD');

        let date_1 = new Date(
          this.DatePipe.transform(currentDate, 'yyyy-MM-dd')
        ).getTime();
        let date_2 = new Date(
          this.DatePipe.transform(toDate, 'yyyy-MM-dd')
        ).getTime();

        if (date_2 > date_1) {
          toDate = this.DatePipe.transform(currentDate, 'yyyy-MM-dd');
        }
        $('#exe_EndDate').attr(
          'min',
          this.DatePipe.transform(fromDate, 'yyyy-MM-dd')
        );
        $('#exe_EndDate').attr(
          'max',
          this.DatePipe.transform(toDate, 'yyyy-MM-dd')
        );
        this.failedRCATestStepsform.controls['exe_EndDate'].setValue(toDate);
      }
      this.fetchFailedTestStepsCount(
        this.brand_name,
        this.fromDate,
        this.toDate,
        this.environment,
        this.testLevel
      );
    }
  }

  onToDateChange(event: any, e) {
    this.toDate = event.target.value;
    console.log('from: ', this.fromDate);
    console.log('To: ', this.toDate);
    this.fetchFailedTestStepsCount(
      this.brand_name,
      this.fromDate,
      this.toDate,
      this.environment,
      this.testLevel
    );

    let toDate = this.DatePipe.transform(e.target.value, 'yyyy-MM-dd');
    let toUIDate = this.DatePipe.transform(e.target.value, 'd MMM yyyy');
    let currentDate = new Date();
    let currentmonth = currentDate.getMonth();
    let currentyear = currentDate.getFullYear();
    let currentmonth1 = new Date(toDate).getMonth();
    let currentyear1 = new Date(toDate).getFullYear();
    this.selectedDate2 = ' - ' + toUIDate;
    console.log('Selected Date2 ' + this.selectedDate2);
    if (
      new Date(this.DatePipe.transform(currentDate, 'yyyy-MM-dd')).getTime() ===
      new Date(this.DatePipe.transform(toDate, 'yyyy-MM-dd')).getTime()
    ) {
      toDate = moment(toDate)
        .subtract(1, 'months')
        .format('YYYY-MM-DD');
      $('#exe_StartDate').attr(
        'min',
        this.DatePipe.transform(toDate, 'yyyy-MM-dd')
      );
    } else {
      let date_1 = new Date(currentyear, currentmonth);
      let date_2 = new Date(currentyear1, currentmonth1);
      if (date_1.getTime() === date_2.getTime()) {
        toDate = moment(toDate)
          .subtract(1, 'months')
          .format('YYYY-MM-DD');
        let fromDate = moment(
          this.DatePipe.transform(e.target.value, 'yyyy-MM-dd')
        )
          .add(1, 'months')
          .format('YYYY-MM-DD');
        $('#exe_StartDate').attr(
          'min',
          this.DatePipe.transform(toDate, 'yyyy-MM-dd')
        );
        $('#exe_StartDate').attr(
          'max',
          this.DatePipe.transform(currentDate, 'yyyy-MM-dd')
        );
      } else {
        toDate = moment(toDate)
          .subtract(1, 'months')
          .format('YYYY-MM-DD');
        let fromDate = moment(
          this.DatePipe.transform(e.target.value, 'yyyy-MM-dd')
        )
          .add(1, 'months')
          .format('YYYY-MM-DD');
        $('#exe_StartDate').attr(
          'min',
          this.DatePipe.transform(toDate, 'yyyy-MM-dd')
        );
        $('#exe_StartDate').attr(
          'max',
          this.DatePipe.transform(fromDate, 'yyyy-MM-dd')
        );
      }
    }
  }

  changeBrand(event: any) {
    this.brand_name = event.target.value;
    if (
      this.fromDate == null &&
      this.toDate == null &&
      this.environment == null &&
      this.testLevel == null
    ) {
      this.fetchFailedTestStepsCount(this.brand_name, null, null, null, null);
    } else {
      this.fetchFailedTestStepsCount(
        this.brand_name,
        this.fromDate,
        this.toDate,
        this.environment,
        this.testLevel
      );
    }
  }

  validateDates(sDate: string, eDate: string) {
    this.isValidDate = true;
    if (sDate == null || eDate == null) {
      this.error = {
        isError: true,
        errorMessage: 'Start date and end date are required.'
      };
      this.isValidDate = false;
    }

    if (sDate != null && eDate != null && eDate < sDate) {
      this.error = {
        isError: true,
        errorMessage: 'To date should be greater than from date.'
      };
      this.isValidDate = false;
    }
    let currenDate = new Date();
    let curr_date = this.DatePipe.transform(currenDate, 'yyyy-MM-dd');
    console.log('curr_date', curr_date);
    if (sDate != null && eDate != null && curr_date < eDate) {
      this.error = {
        isError: true,
        errorMessage:
          'To date should not be greater than today' + "'" + 's date.'
      };
      this.isValidDate = false;
    }
    return this.isValidDate;
  }
  changeTestLevel(e) {
    this.testLevel = e.target.value;

    if (this.brand_name != 0 && this.testLevel != null) {
      this.fetchFailedTestStepsCount(
        this.brand_name,
        this.fromDate,
        this.toDate,
        this.environment,
        this.testLevel
      );
    } else if (
      this.brand_name == 0 &&
      this.fromDate == null &&
      this.toDate == null &&
      this.environment != null
    ) {
      this.fetchFailedTestStepsCount(
        0,
        this.fromDate,
        this.toDate,
        null,
        this.testLevel
      );
    } else {
      this.fetchFailedTestStepsCount(
        0,
        this.fromDate,
        this.toDate,
        this.environment,
        this.testLevel
      );
    }
  }

  onEnvChange(event: any) {
    this.environment = event.target.value;

    if (this.brand_name != 0) {
      this.fetchFailedTestStepsCount(
        this.brand_name,
        this.fromDate,
        this.toDate,
        this.environment,
        this.testLevel
      );
    } else if (
      this.brand_name == 0 &&
      this.fromDate == null &&
      this.toDate == null &&
      this.testLevel != null
    ) {
      this.fetchFailedTestStepsCount(
        0,
        this.fromDate,
        this.toDate,
        this.environment,
        null
      );
    } else {
      this.fetchFailedTestStepsCount(
        0,
        this.fromDate,
        this.toDate,
        this.environment,
        this.testLevel
      );
    }
  }

  fetchFailedTestStepsCount(
    brand_name: any,
    fromDate: string | null,
    toDate: string | null,
    environment: string | null,
    testLevel: string | null
  ) {
    const requestBody = {
      startDate: fromDate,
      endDate: toDate,
      environment: environment,
      brand_name: brand_name,
      testLevel: testLevel
    };
    this.dataservice
      .getFailedTestStepsCount(requestBody)
      .subscribe((Res: any) => {
        console.log('Failed Test Steps.....', Res);

        this.Fail_count = [];
        this.barChartLabels = [];
        if (Res.length > 0) {
          Res.forEach(element => {
            this.barChartLabels.push(element.testStepName);
            this.Fail_count.push(element.count);
          });
        }
        (this.chartOptions.xAxis = {
          categories: this.barChartLabels
        }),
          (this.chartOptions.series = [
            {
              name: 'Fail Count',
              data: this.Fail_count,
              showInLegend: false
            }
          ]);
        this.updateFlag = true;
      });
  }
  fetchBrandsbyRegion(regionId: number) {
    this.brandLists = [];
    this.dataservice.getBrandsByRegionId(regionId).subscribe((response: {}) => {
      this.brandLists = response;
      console.log('this.brandList.....>', this.brandLists);
      this.brandLists = orderBy(this.brandLists, ['brandName'], ['asc']);
    }),
      error => {
        console.log(error);
      };
  }

  failedRCATestStepsformSubmit() {
    this.isfromOninit = false;
    const requestBody = {
      startDate: this.fromDate,
      endDate: this.toDate
    };
    if (this.failedRCATestStepsform.invalid) {
      return;
    } else {
      this.dataservice
        .getFailedTestStepsCount(requestBody)
        .subscribe((Res: any) => {
          console.log('Failed Test Steps.....', Res);

          this.Fail_count = [];
          this.barChartLabels = [];
          if (Res.length > 0) {
            Res.forEach(element => {
              this.barChartLabels.push(element.testStepName);
              this.Fail_count.push(element.count);
            });
          }
          (this.chartOptions.xAxis = {
            categories: this.barChartLabels
          }),
            (this.chartOptions.series = [
              {
                name: 'Fail Count',
                data: this.Fail_count,
                showInLegend: false
              }
            ]);
          this.updateFlag = true;
        });
    }
  }
}
