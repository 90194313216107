import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Chart } from 'chart.js';
import * as Highcharts from 'highcharts';
// import * as $ from 'jquery';
import $ from 'jquery';
// import * as moment from 'moment';
import moment from 'moment';
// import { ToastrManager } from 'ng6-toastr-notifications';
import { ToastrService } from 'ngx-toastr';
import { DataService } from '../../../../shared/services/data.service';
import { Type } from '../../../models/type';
import {orderBy} from 'lodash';
import { Constvar } from '../../../../models/constVar';
import { LoaderService } from '../../../../shared/services/loader.service';
@Component({
  selector: 'wtaf-test-execution-trend',
  templateUrl: './test-execution-trend.component.html',
  styleUrls: ['./test-execution-trend.component.css']
})
export class TestExecutionTrendComponent implements OnInit {
  apiUiFlag: boolean;
  load=false;
  chart: any;
  highcharts: any = Highcharts;
  projectTypeId: Number = 0;
  regionList: any = [];
  regionId: Number = 0;
  brandList: any = [];
  brandId: Number = 0;
  trendExecutionFromDate: any;
  trendExecutionToDate: any;
  dateYesterday: any;
  dateToday: any;
  projectTypeList: any = [];
  projectNameList: any = [];
  projectName: String = '';
  testSuiteList: any = [];
  testSuiteid: Number = 0;
  platformList: any = [];
  platformId: Number = 0;
  projectTypeName: String = '';
  testSuiteName: String = '';
  regionName: string = '';
  environment_type = 'STG';
  projecttypeId: any;
  testExewidgetFilterform: UntypedFormGroup;
  isSubmitted = false;
  toDate: any;
  dropdownSettings = {};
  dropdownSettingsTestSuite = {};
  dropdownList = [];
  selectedItems = [];
  selectedItemsSuite = [];
  barChartLabels: any = [];
  dataSet_total: any = [];
  dataSet_pass: any = [];
  dataSet_fail: any = [];
  dataSet_unknown: any = [];
  error: any = { isError: false, errorMessage: '' };
  isValidDate: any;
  date_labels: any = [];
  fromDate: any;
  userList: any = [];
  environmentList = ['Dev','Prod','QA','STG'];
  closeDropdownSelection = false;
  updateFlag = true;
  chartOptions: any ;
  isfromOninit = false;
  constructor(
    private dataservice: DataService,
    public datePipe: DatePipe,
    public fb: UntypedFormBuilder,
    public toastr: ToastrService,
    public loaderservice: LoaderService
  ) {
    let nowDate = new Date();
    this.toDate = this.datePipe.transform(nowDate, 'yyyy-MM-dd');
    this.fromDate = moment()
      .subtract('days', 7)
      .format('YYYY-MM-DD');
   this.testExewidgetFilterform = this.fb.group({
    projectTypeId: ['', [Validators.required]],
    regionId: ['', [Validators.required]],
    brandId: ['', [Validators.required]],
    // productId: ['',[Validators.required]],
    // productName: ['', [Validators.required]],
    //testSuiteName: ['', [Validators.required]],
    //testSuiteId: ['', [Validators.required]],
    //profileId: ['', [Validators.required]],
     platformId: ['', [Validators.required]],
     environment: ['STG', [Validators.required]],
    // startDate: [this.fromDate, [Validators.required]],
    // endDate: [this.toDate, [Validators.required]],
    // projectName: []
  });
  }

  ngOnInit() {

    this.loaderservice.show()
    setTimeout(()=>{
      this.loaderservice.hide()

    },15000)


    this.load=true;
    this.dropdownSettings = {
      singleSelection: true,
      idField: 'projectName',
      textField: 'projectName',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 1,
      allowSearchFilter: true
      // closeDropDownOnSelection: this.closeDropdownSelection
    };

    this.dropdownSettingsTestSuite = {
      singleSelection: true,
      idField: 'testSuiteName',
      textField: 'testSuiteName',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 1,
      allowSearchFilter: true
      // closeDropDownOnSelection: this.closeDropdownSelection
    };

    $('#testSuiteExecutionTrendFromDate').attr('max', this.toDate);
    $('#testSuiteExecutionTrendToDate').attr('max', this.toDate);
    // this.dataservice.isfromWidget = true;
    
    this.getAllProjectType(true);
    // this.getAllRegion(1);
    // this.getBrandsByRegionId(1);
    //this.getAllUser();
    // this.getAllProjectName(0,0,0);
    // this.getPlatformByProjectTypeId(1);
    // let Bar = document.getElementById('Bar');

    // this.getAlltestsuites();

    for (let i = 1; i < 8; i++) {
      this.date_labels.push(
        moment()
          .subtract('days', i)
          .format('YYYY-MM-DD')
      );
    }
    console.log('this.date_labels', this.date_labels);

    let Bar = document.getElementById('Bar');

    // Highcharts.setOptions({
    //   colors: ['#007bff', '#dc3545', '#ffc107']
    // });

    this.chartOptions = {
      chart: {
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
        type: 'column'
      },
      xAxis: {
        categories: this.date_labels
      },
      yAxis: {
        min: 0,
        title: {
          text: 'Test Suite Execution Count'
        },
        stackLabels: {
          enabled: false,
          style: {
            fontWeight: 'bold',
            color:
              // theme
              (Highcharts.defaultOptions.title.style &&
                Highcharts.defaultOptions.title.style.color) ||
              'gray'
          }
        }
      },
  
      mapNavigation: {
        enableMouseWheelZoom: true
      },
      title: {
        text: ''
  },
      tooltip: {
          headerFormat: '<b>{point.x}</b><br/>',
        //pointFormat: '{series.name}: {point.y}<br/>Total: {point.stackTotal}'
      },
      plotOptions: {
        column: {
          stacking: 'normal',
          dataLabels: {
            enabled: true
          }
        }
      },
      series: []
    };
  
    this.chartOptions.series = [
      {
        name: 'Passed',
        data: [0, 0, 0, 0, 0, 0, 0],
        color:'#1b7730'
      },
      {
        name: 'Failed',
        data: [0, 0, 0, 0, 0, 0, 0],
        color : '#dc3545'
      },
    ];
    this.updateFlag = true;


    // this.chart = new Chart(Bar, {
    //   type: 'bar',
    //   data: {
    //     labels: this.date_labels,
    //     datasets: [
    //       {
    //         label: 'Passed',
    //         data: [47, 20, 55, 33, 45, 32, 24],
    //         backgroundColor: '#1E4785'
    //       },

    //       {
    //         label: 'Failed',
    //         data: [40, 60, 25, 24, 21, 52, 21],
    //         backgroundColor: '#70A1E9'
    //       },
    //       // {
    //       //   label: 'Unknown',
    //       //   data: [87, 40, 15, 34],
    //       //   backgroundColor: '#ffc107'
    //       // },
    //       // {
    //       //   label: 'Total',
    //       //   data: [174, 120, 95, 91],
    //       //   backgroundColor: '#28a745'
    //       // }
    //     ]
    //   },

    //   options: {
    //     responsive: true,
    //     maintainAspectRatio: true,
    //     legend: {
    //       position: 'top'
    //     },
    //     scales: {
    //       xAxes: [
    //         {
    //           stacked: true
    //         }
    //       ],
    //       yAxes: [
    //         {
    //           stacked: true
    //         }
    //       ]
    //     }
    //   }
    // });

    // document.querySelector('.main').addEventListener('scroll', function() {
    //   let isChrome = !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime);
    //   if (isChrome) {
    //     document.querySelector('input').blur();
    //   }
    // }, false);
  }
  //   toggleCloseDropdownSelection() {
  //     this.closeDropdownSelection = !this.closeDropdownSelection;
  //     this.dropdownSettings = Object.assign({}, this.dropdownSettings,{closeDropDownOnSelection: this.closeDropdownSelection});
  // }

  onItemSelectProductName(item: any) {
    console.log(item);
    console.log(this.selectedItems);
    // this.dataservice.isfromWidget = false;
    this.projectName = item.projectName;
    // this.testExewidgetFilterform.controls['productName'].setValue(item.projectName);
    this.testExewidgetFilterform.controls['testSuiteId'].setValue('');
    this.testExewidgetFilterform.controls['testSuiteName'].setValue('');
    this.testSuiteList = [];
    this.brandId = this.testExewidgetFilterform.get('brandId').value;
    this.regionId = this.testExewidgetFilterform.get('regionId').value;
    this.projecttypeId = this.testExewidgetFilterform.get(
      'projectTypeId'
    ).value;

    this.getAllTestSuite(
      this.regionId,
      this.brandId,
      this.projecttypeId,
      item.projectName
    );
  }

  onItemSelectSuite(item: any) {
    console.log(item);
    console.log(this.selectedItemsSuite);
    this.testSuiteName = item.testSuiteName;
    let index = this.testSuiteList.findIndex(
      el => el.testSuiteName == this.testSuiteName
    );

    this.testSuiteid = this.testSuiteList[index].testSuiteId;
    this.testExewidgetFilterform.controls['testSuiteId'].setValue(
      this.testSuiteid
    );
    console.log(this.testSuiteList[index].testSuiteId);
    // this.dataservice.isfromWidget = false;
    // this.projectName = item.projectName;
    // this.testExewidgetFilterform.controls['productName'].setValue(item.projectName);
  }
  OnItemDeSelect(item: any) {
    console.log(item);
    console.log(this.selectedItems);
    this.projectName = '';
    delete item.projectName;
    this.testExewidgetFilterform.controls['productName'].setValue('');
  }
  onSelectAll(items: any) {
    console.log(items);
  }
  onDeSelectAll(items: any) {
    console.log(items);
  }
  onkeyUP() {
    const container = document.querySelector('.container');
    const input = document.querySelector('#projectNamewid2');

    container.addEventListener(
      'scroll',
      function() {
        document.querySelector('input').blur();
      },
      false
    );
  }
  getAllRegion(Projecttypeid) {
    this.dataservice
      .getAllRegionByProjectType(Projecttypeid)
      .subscribe(async (response: {}) => {
        this.regionList = response;
        this.regionList = orderBy(this.regionList, ['regionCode'],['asc']);
        // this.regionList.unshift(
        //   this.regionList.splice(
            let index=this.regionList.findIndex(item => item.regionName === 'NAR');
        //     1
        //   )[0]
        // );
        if(index<0)
        {
          index=index+1;
        }
        if (response != null) {
          console.log('region id' + this.regionList[0].regionId);
          this.testExewidgetFilterform.controls['regionId'].setValue(
            this.regionList[index].regionId
          );
          this.regionName = this.regionList[0].regionName;
          this.getBrandsByRegionId(
            this.testExewidgetFilterform.get('regionId').value
          );
          // await this.getBrandsByRegionId(this.regionList[0].regionId);
        }
      });
  }
  async getAllProjectName(regionId, brandId, projecttypeId) {
    await this.dataservice
      .getProjectNameByTypeId(brandId, projecttypeId, regionId)
      .subscribe(allProjNameRes => {
        this.projectNameList = allProjNameRes;
        console.log('all product names', this.projectNameList);
      });
  }
  async getBrandsByRegionId(regionId) {
    await this.dataservice
      .getBrandsByRegionId(regionId)
      .toPromise()
      .then(async (response: {}) => {
        this.brandList = response;
        this.brandList = orderBy(this.brandList, ['brandName'],['asc']);
        if (response != null) {
          if (this.brandList) {
            this.regionId = this.testExewidgetFilterform.get('regionId').value;
            this.projecttypeId = this.testExewidgetFilterform.get(
              'projectTypeId'
            ).value;
            if (this.brandList.length == 1) {
              this.testExewidgetFilterform.controls['brandId'].setValue(
                this.brandList[0].brandId
              );
            } else {
              this.testExewidgetFilterform.controls['brandId'].setValue(0);
              
            }
            // this.brandList.unshift(
            //   this.brandList.splice(
              // let index= this.brandList.findIndex(
                //  item => item.brandName.toLowerCase() === 'whirlpool');
            
            //     1
            //   )[0]
            // );
            //this.testExewidgetFilterform.controls['brandId'].setValue(
            //  this.brandList[index].brandId
            //);
            this.brandId = this.brandList[0].brandId;
            console.log('this.isfromOninit.............',this.isfromOninit);
            
            if(this.isfromOninit)
            {
             await this.onFormSubmit();
            }
          }
          this.getAllProjectName(
            this.regionId,
            this.brandId,
            this.projecttypeId
          );
        } else {
        }
      })
      .catch(err => {
        console.log(err);
        this.toastr.error('Something went wrong');
      });
  }
  async getAllProjectType(isfromOninit) {
    this.isfromOninit= isfromOninit;
    await this.dataservice
      .getAllProjectType()
      .toPromise()
      .then(async response => {
        // Success
        this.projectTypeList = response;
        console.log('this.projectTypeList.length');
        console.log(this.projectTypeList.length);
        if (response != null) {
          this.testExewidgetFilterform.controls['projectTypeId'].setValue(
            this.projectTypeList[0].projectTypeId
          );
          this.getPlatformByProjectTypeId(
            this.projectTypeList[0].projectTypeId
          );
          await this.getAllRegion(this.projectTypeList[0].projectTypeId);
        }
      });
  }
  async getAlltestsuites() {
    this.dataservice
      .getTestSuiteNameByProjectName('multiple test suite')
      .subscribe((response: {}) => {
        console.log('Test Suite');
        console.log(response);
        this.testSuiteList = response;
        if (response != null) {
          if (this.testSuiteList.length == 1) {
          } else {
          }
        }
      });
  }
  async getPlatformByProjectTypeId(projectTypeId) {
    await this.dataservice.getPlatformByProjectTypeId(projectTypeId).subscribe(
      (response: {}) => {
        this.platformList = response;
        console.log(this.platformList);
        if (this.platformList != null) {
          if (this.platformList.length == 1) {
            this.testExewidgetFilterform.controls['platformId'].setValue(
              this.platformList[0].platformId
            );
          } else {
            this.testExewidgetFilterform.controls['platformId'].setValue(0);
          }
        }
      },
      error => {
        console.log(error.message);
        this.toastr.error('Something went Wrong ');
      }
    );
  }
  async getAllTestSuite(regionId, brandId, projectTypeId, projectName) {
    let TestSuiteName = Type.TestSuiteName;
    let asc = Type.ascending;
    await this.dataservice
      .getAllTestSuite(
        TestSuiteName,
        asc,
        regionId,
        brandId,
        projectTypeId,
        projectName,
        0,
        0
      )
      .toPromise()
      .then(async (response: {}) => {
        this.testSuiteList = response;
        // this.dataservice.customFilter['testSuiteName'] = '';
        console.log('getAllTestSuite----response', response);
      });
  }

  /* get all users  */
  // getAllUser() {
  //   this.dataservice.getAllUser().subscribe((response: {}) => {
  //     this.userList = response;
  //     this.testExewidgetFilterform.controls['profileId'].setValue(0);
  //     console.log('response', response);
  //     // this.dataservice.customFilter['userName'] = '';
  //   });
  // }
  changeProjectType(e) {
    
    this.dataservice.isfromWidget = false;
    console.log(e);

    this.projecttypeId = e.target.value;
    console.log(this.projecttypeId);

    this.regionList = [];
    this.brandList = [];
    this.brandId = 0;
    this.projectNameList = [];
    this.testSuiteList = [];
    this.testExewidgetFilterform.controls['regionId'].setValue('');
    // this.testExewidgetFilterform.controls['testSuiteId'].setValue('');
    // this.testExewidgetFilterform.controls['testSuiteName'].setValue('');
    // this.testExewidgetFilterform.controls['productName'].setValue('');
    let objProjectType = this.projectTypeList.filter(
      pl => pl.projectTypeId == this.projecttypeId
    );

    this.projectTypeName = objProjectType[0].projectTypeName;
    console.log('project type -->', this.projectTypeName);
    if(this.projectTypeName == Constvar.API)
    {
      this.apiUiFlag = true;
      this.getAllRegion(parseInt(e.target.value));
    }
    else{
      this.apiUiFlag = false;
    this.getPlatformByProjectTypeId(parseInt(e.target.value));
    this.getAllRegion(parseInt(e.target.value));
  }
  
  }

  changeRegion(e) {
    this.dataservice.isfromWidget = false;
    this.projectNameList = [];
    this.testSuiteList = [];
    let regionName = this.regionList.filter(region => {
      return region.regionId == parseInt(e.target.value);
    });
    this.regionName = regionName[0].regionName;
    console.log(this.regionName);
    // this.testExewidgetFilterform.controls['testSuiteId'].setValue('');
    // this.testExewidgetFilterform.controls['testSuiteName'].setValue('');
    // this.testExewidgetFilterform.controls['productName'].setValue('');
    this.getBrandsByRegionId(parseInt(e.target.value));
  }
  changeBrand(e) {
    this.dataservice.isfromWidget = false;
    this.brandId = parseInt(e.target.value);
    // this.testExewidgetFilterform.controls['testSuiteId'].setValue('');
    // this.testExewidgetFilterform.controls['testSuiteName'].setValue('');
    // this.testExewidgetFilterform.controls['productName'].setValue('');
    this.regionId = this.testExewidgetFilterform.get('regionId').value;
    // this.projecttypeId = this.testExewidgetFilterform.get(
    //   'projectTypeId'
    // ).value;
    // this.getAllProjectName(this.regionId, this.brandId, this.projecttypeId);
  }

  changeProjectName(e) {
    this.dataservice.isfromWidget = false;
    this.testExewidgetFilterform.controls['testSuiteId'].setValue('');
    this.testExewidgetFilterform.controls['testSuiteName'].setValue('');
    this.testSuiteList = [];
    this.brandId = this.testExewidgetFilterform.get('brandId').value;
    this.regionId = this.testExewidgetFilterform.get('regionId').value;
    this.projecttypeId = this.testExewidgetFilterform.get(
      'projectTypeId'
    ).value;


    this.getAllTestSuite(
      this.regionId,
      this.brandId,
      this.projecttypeId,
      e.target.value
    );
  }
  onFromDateChange(e) {
    let fromDate = this.datePipe.transform(e.target.value, 'yyyy-MM-dd');
    let currentDate = new Date();
    let currentmonth = currentDate.getMonth();
    let currentyear = currentDate.getFullYear();
    let currentmonth1 = new Date(fromDate).getMonth();
    let currentyear1 = new Date(fromDate).getFullYear();
    if (
      new Date(this.datePipe.transform(currentDate, 'yyyy-MM-dd')).getTime() ==
      new Date(this.datePipe.transform(fromDate, 'yyyy-MM-dd')).getTime()
    ) {
      let maxDate = fromDate;
      // fromDate = moment(fromDate).subtract(1, 'months').format('YYYY-MM-DD');
      $('#testSuiteExecutionTrendToDate').attr(
        'min',
        this.datePipe.transform(fromDate, 'yyyy-MM-dd')
      );
      $('#testSuiteExecutionTrendToDate').attr(
        'max',
        this.datePipe.transform(maxDate, 'yyyy-MM-dd')
      );
    } else {
      let date_1 = new Date(currentyear, currentmonth);
      let date_2 = new Date(currentyear1, currentmonth1);
      if (date_1.getTime() === date_2.getTime()) {
        let maxDate = fromDate;
        // fromDate = moment(fromDate).subtract(1, 'months').format('YYYY-MM-DD');
        $('#testSuiteExecutionTrendToDate').attr(
          'min',
          this.datePipe.transform(fromDate, 'yyyy-MM-dd')
        );
        $('#testSuiteExecutionTrendToDate').attr(
          'max',
          this.datePipe.transform(currentDate, 'yyyy-MM-dd')
        );
      } else {
        // fromDate = moment(fromDate).subtract(1, 'months').format('YYYY-MM-DD');
        let toDate = moment(
          this.datePipe.transform(e.target.value, 'yyyy-MM-dd')
        )
          .add(1, 'months')
          .format('YYYY-MM-DD');
        let date_1 = new Date(
          this.datePipe.transform(currentDate, 'yyyy-MM-dd')
        ).getTime();
        let date_2 = new Date(
          this.datePipe.transform(toDate, 'yyyy-MM-dd')
        ).getTime();

        if (date_2 > date_1) {
          toDate = this.datePipe.transform(currentDate, 'yyyy-MM-dd');
        }
        $('#testSuiteExecutionTrendToDate').attr(
          'min',
          this.datePipe.transform(fromDate, 'yyyy-MM-dd')
        );
        $('#testSuiteExecutionTrendToDate').attr(
          'max',
          this.datePipe.transform(toDate, 'yyyy-MM-dd')
        );
        this.testExewidgetFilterform.controls['endDate'].setValue(toDate);
      }
    }
  }
  onToDateChange(e) {
    let toDate = this.datePipe.transform(e.target.value, 'yyyy-MM-dd');
    let currentDate = new Date();
    let currentmonth = currentDate.getMonth();
    let currentyear = currentDate.getFullYear();
    let currentmonth1 = new Date(toDate).getMonth();
    let currentyear1 = new Date(toDate).getFullYear();
    if (
      new Date(this.datePipe.transform(currentDate, 'yyyy-MM-dd')).getTime() ===
      new Date(this.datePipe.transform(toDate, 'yyyy-MM-dd')).getTime()
    ) {
      let maxDate = toDate;
      toDate = moment(toDate)
        .subtract(1, 'months')
        .format('YYYY-MM-DD');
      $('#testSuiteExecutionTrendFromDate').attr(
        'min',
        this.datePipe.transform(toDate, 'yyyy-MM-dd')
      );
      $('#testSuiteExecutionTrendFromDate').attr(
        'max',
        this.datePipe.transform(maxDate, 'yyyy-MM-dd')
      );
    } else {
      let date_1 = new Date(currentyear, currentmonth);
      let date_2 = new Date(currentyear1, currentmonth1);
      if (date_1.getTime() === date_2.getTime()) {
        toDate = moment(toDate)
          .subtract(1, 'months')
          .format('YYYY-MM-DD');
        let fromDate = moment(
          this.datePipe.transform(e.target.value, 'yyyy-MM-dd')
        )
          .add(1, 'months')
          .format('YYYY-MM-DD');
        $('#testSuiteExecutionTrendFromDate').attr(
          'min',
          this.datePipe.transform(toDate, 'yyyy-MM-dd')
        );
        // $('#testSuiteExecutionTrendFromDate').attr('max', this.datePipe.transform(currentDate, 'yyyy-MM-dd'));
      } else {
        toDate = moment(toDate)
          .subtract(1, 'months')
          .format('YYYY-MM-DD');
        let fromDate = moment(
          this.datePipe.transform(e.target.value, 'yyyy-MM-dd')
        )
          .add(1, 'months')
          .format('YYYY-MM-DD');
        $('#testSuiteExecutionTrendFromDate').attr(
          'min',
          this.datePipe.transform(toDate, 'yyyy-MM-dd')
        );
        $('#testSuiteExecutionTrendFromDate').attr(
          'max',
          this.datePipe.transform(e.target.value, 'yyyy-MM-dd')
        );
      }
    }
  }

  changeTestsuite(e) {
    //due to using
    let index = this.testSuiteList.findIndex(
      el => el.testSuiteName == e.target.value
    );
    this.testSuiteName = e.target.value;
    this.testSuiteid = this.testSuiteList[index].testSuiteId;
    this.testExewidgetFilterform.controls['testSuiteId'].setValue(
      this.testSuiteid
    );
    console.log(this.testSuiteList[index].testSuiteId);
    // l this.testExewidgetFilterform.controls['testSuiteId'].setValue(this.testSuiteid);
  }

  // changeUser(e) {
  //   let userId = parseInt(e.target.value);
  //   this.testExewidgetFilterform.controls['profileId'].setValue(
  //     parseInt(e.target.value)
  //   );
  //   console.log('userId', userId);
  // }

  GetEnv_type(e) {
    this.environment_type = e.target.value;
  }

  onFormSubmit() {
    // this.testExewidgetFilterform.controls['testSuiteId'].setValue(
    //   this.testSuiteid
    // );
    // this.projectName=this.testExewidgetFilterform.get('productName').value;
    // this.testExewidgetFilterform.controls['projectName'].setValue(
    //   this.projectName
    // );
    console.log(this.testExewidgetFilterform.value);
    this.isSubmitted = true;
    this.isfromOninit=false;
    // this.isValidDate = this.validateDates(
    //   this.datePipe.transform(
    //     this.testExewidgetFilterform.controls['startDate'].value,
    //     'yyyy-MM-dd'
    //   ),
    //   this.datePipe.transform(
    //     this.testExewidgetFilterform.controls['endDate'].value,
    //     'yyyy-MM-dd'
    //   )
    // );
    if(this.projectTypeName == Constvar.API){
    let formControl: UntypedFormControl = this.testExewidgetFilterform.get(
      'platformId'
    ) as UntypedFormControl;
    formControl.clearValidators();
    formControl.setValidators(null);
    formControl.updateValueAndValidity();
    this.testExewidgetFilterform.get('platformId').setValue('');
    }
    if (this.testExewidgetFilterform.invalid) {
      return;
    } else {
      setTimeout(() => {
        this.loaderservice.show(); 
      }, 3000);
      this.dataservice
        .testExeTrend(this.testExewidgetFilterform.value)
        .subscribe(
          (res: any) => {
            this.loaderservice.show();
            this.load=false;
            console.log('res', res);
            let mindate = moment(this.toDate)
              .subtract(24, 'months')
              .format('YYYY-MM-DD');
            $('#testSuiteExecutionTrendFromDate').attr('max', this.toDate);
            $('#testSuiteExecutionTrendToDate').attr('max', this.toDate);
            $('#testSuiteExecutionTrendFromDate').attr('min', mindate);
            $('#testSuiteExecutionTrendToDate').attr('min', mindate);
            this.error = { isError: false, errorMessage: '' };
            this.dataSet_fail = [];
            this.dataSet_pass = [];
            this.dataSet_total = [];
            this.barChartLabels = [];
            this.dataSet_unknown = [];
            if (res.length === 0) {
              this.toastr.error('No records found');
            } else 
           
            if (res != null) {
              this.chartOptions.series=[];
              res.map(element => {
                this.dataSet_fail.push(element.failed);
                this.dataSet_pass.push(element.passed);
                this.dataSet_unknown.push(element.unknown);
                this.dataSet_total.push(element.total);
                this.barChartLabels.push(element.date);
              });

              //this.chartOptions.destroy();
              //this.chart.destroy();
              // document.getElementById('barchart').innerHTML = '&nbsp;';
              // document.getElementById('barchart').innerHTML =
                // '<canvas id="Bar" ></canvas>';
              console.log(this.dataSet_pass);
              let Bar = document.getElementById('Bar');
              this.chartOptions={
                xAxis:{
                categories: this.barChartLabels
              }}
              this.chartOptions.series = [
                {
                  name: 'Passed',
                  data: this.dataSet_pass
                },
                {
                  name: 'Failed',
                  data: this.dataSet_fail
                }
              ]
              this.updateFlag = true;







              // this.chart = new Chart(Bar, {
              //   type: 'bar',
              //   data: {
              //     labels: this.barChartLabels,
              //     datasets: [
              //       {
              //         label: 'Passed',
              //         data: this.dataSet_pass,
              //         backgroundColor: '#007bff'
              //       },
              //       {
              //         label: 'Failed',
              //         data: this.dataSet_fail,
              //         backgroundColor: '#dc3545'
              //       },

                    // {
                    //   label: 'Unknown',
                    //   data: this.dataSet_unknown,
                    //   backgroundColor: '#ffc107'
                    // },

                    // {
                    //   label: 'Total',
                    //   data: this.dataSet_total,
                    //   backgroundColor: '#28a745'
                    // }
                 // ]
               // },

                // options: {
                //   responsive: true,
                //   maintainAspectRatio: true,
                //   legend: {
                //     position: 'top'
                //   },
                //   scales: {
                //     xAxes: [
                //       {
                //         stacked: true
                //       }
                //     ],
                //     yAxes: [
                //       {
                //         stacked: true,
                //         ticks: {
                //           beginAtZero: true
                //         }
                //       }
                //     ]
                //   }
                // }
             // });
            }
          },
          error => {
            $('#testSuiteExecutionTrendFromDate').attr('max', this.toDate);
            $('#testSuiteExecutionTrendToDate').attr('max', this.toDate);
            this.load=false;
            this.toastr.error("Someting went Wrong");
            this.error = { isError: false, errorMessage: '' };
          }
        );
    }
  }
  validateDates(sDate: string, eDate: string) {
    this.isValidDate = true;
    if (sDate == null || eDate == null) {
      this.error = {
        isError: true,
        errorMessage: 'Start date and end date are required.'
      };
      this.isValidDate = false;
    }

    if (sDate != null && eDate != null && eDate < sDate) {
      this.error = {
        isError: true,
        errorMessage: 'To date should be greater than from date.'
      };
      this.isValidDate = false;
    }
    return this.isValidDate;
  }
  ngAfterViewInit() {}
}
